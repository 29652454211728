import proj1_img from '../assets/proj1.jpeg';
import proj2_img from '../assets/proj2.jpeg';
import proj3_img from '../assets/proj3.jpeg';
import proj4_img from '../assets/proj4.jpg';
import proj5_img from '../assets/proj5.jpg';
import proj6_img from '../assets/proj6.jpg';
import proj7_img from '../assets/proj7.jpg';
import proj8_img from '../assets/proj8.jpg';
export const PROJECTS = [
  {
    id: '1',
    title: 'Share Your Blogs',
    description:
      'A platform developed and in development for the developers and knowledge seekers to Exchange our thoughts. By adding the URL and appropriate tags to the Blog',
    image: {
      src: proj1_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://share-your-blogs.netlify.app/',
      repo: 'https://github.com/vaishak-web/react_memories_app',
    },
    technologies: [
      '#react',
      '#styled-components',
      '#express',
      '#mongodb',
      '#sass',
    ],
  },
  {
    id: '2',
    title: 'Color Generator',
    description:
      'Color Generator Setup "Get 20 Tints & 20 Shades for your Input HEX-Code".',
    image: {
      src: proj2_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://hexcolor-code-generator.netlify.app/',
      repo: 'https://github.com/vaishak-web/react_colour_palette',
    },
    technologies: [
      '#react',
      '#styled-components',
      '#sass',
    ],
  },
  {
    id: '3',
    title: 'Grocery Bud',
    description:
      'A place where you can save your list of groceries. List saves your list till localStorage is emptied.',
    image: {
      src: proj3_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://reactjslearn-grocery-bud.netlify.app/',
      repo: 'https://github.com/vaishak-web/react_to_do_list',
    },
    technologies: [
      '#react',
      '#styled-components',
      '#sass',
    ],
  },
  {
    id: '4',
    title: 'Chat Dashboard',
    description:
      'A business messaging platform that connects customers from multiple social channels. Uses ReactJS for Real-time Analytics Dashboard',
    image: {
      src: proj4_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#react',
      '#redux',
      '#socket.io',
      '#scss',
      '#formik',
      '#restapi',
      '#mongodb',
    ],
  },
  {
    id: '5',
    title: 'GYM Platform',
    description:
      'A platform to serve a complete GYM Management System(Website, CMS, RESTful API’s for Flutter App).',
    image: {
      src: proj5_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#bootstrap',
      '#php',
      '#laravel',
      '#restapi',
      '#flutter',
      '#mysql',
    ],
  },
  {
    id: '6',
    title: 'Crick7',
    description:
      'Fantasy sports platform that allows users to play fantasy cricket(Dashboard, RESTful API’s, Payment integration).',
    image: {
      src: proj6_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#bootstrap',
      '#php',
      '#codeigniter',
      '#restapi',
      '#mysql',
    ],
  },
  {
    id: '7',
    title: 'Campaigns',
    description:
      'Connecting retailers and distributors of a multinational corporation. Dashboard to find the MVP among the 50,000+ users.',
    image: {
      src: proj7_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://expo.dev/@v_snaichuk/rn-places',
      repo: 'https://github.com/vsnaichuk/RN-places',
    },
    technologies: [
      '#bootstrap',
      '#php',
      '#laravel',
      '#restapi',
      '#flutter',
      '#mysql',
    ],
  },
  
  {
    id: '8',
    title: 'Tourist Guide',
    description:
      'A site which share information about the forts in Maharashtra.',
    image: {
      src: proj8_img,
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://amazing-shannon-d66f85.netlify.app/',
      repo: 'https://twitter.com/vaishak_web/status/1411763622703894531?s=20',
    },
    technologies: [
      '#react',
      '#styled-components',
      '#sass',
    ],
  },
];
